let config = {
	// 服务器状态
    serverStates: [{
            value: 1,
            label: "关闭",
            tag: "info"
        },
        {
            value: 2,
            label: "正常",
            tag: "success"
        },
        {
            value: 3,
            label: "新服",
            tag: "success"
        },
        {
            value: 4,
            label: "爆满",
            tag: "danger"
        },
        {
            value: 5,
            label: "推荐",
            tag: "warning"
        },
        {
            value: 6,
            label: "维护",
            tag: "primary"
        },
        {
            value: 7,
            label: "预开服",
            tag: "primary"
        },
    ],
}

export default config;
