<template>
    <el-row :gutter="160">
        <el-col :span="24" style="text-align: left;">
            <el-button type="primary" icon="el-icon-plus" @click="onClickAdd">新增定时任务</el-button>
            <el-button type="primary" icon="el-icon-s-operation" @click="onClickSetNum" v-if="true">按量开服设定</el-button>
            <el-button type="success" icon="el-icon-s-operation" @click="onClickAutoRecommend">自动推荐服</el-button>            
        </el-col>
        
        <el-col :span="24">
            <el-table ref="multipleTable" :data="mergeList" :height="tableHeight" border style="width: 100%"
                :row-class-name="tableRowClassName" :cell-style="{padding:4+'px'}" class="serverlist">
                <el-table-column prop="id" label="ID"> </el-table-column>
                <el-table-column prop="title" label="任务名称" show-overflow-tooltip></el-table-column>
                <el-table-column prop="groupname" label="区服列表" show-overflow-tooltip></el-table-column>
                <el-table-column prop="servername" label="区服" show-overflow-tooltip></el-table-column>
                <el-table-column prop="exec_time" label="任务开始时间" show-overflow-tooltip></el-table-column>
                <el-table-column prop="operator" label="切到状态" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <el-tag :type="stateTagFormatColor(scope.row.operate_state)"
                            :effect="stateTagFormatEffect(scope.row.operate_state)">{{stateTagFormatTxt(scope.row.operate_state)}}
                        </el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="开关机" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span v-if="scope.row.action_server==='start'" style="color: #0000FF;">开服</span>
                        <span v-if="scope.row.action_server==='stop'" style="color: #FF0000;">关服</span>
                        <span v-if="scope.row.action_server==='kick_all'" style="color: #FF0000;">踢下线</span>
                        <span v-if="scope.row.action_server==='none'">无</span>
                    </template>
                </el-table-column>
                <el-table-column prop="state" label="任务状态" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span v-if="scope.row.state===0" style="font-weight: bold;">待执行</span>
                        <span v-if="scope.row.state===1" style="font-weight: bold;">已执行</span>
                    </template>                    
                </el-table-column>
                <el-table-column prop="update_time" label="更新时间" show-overflow-tooltip></el-table-column>
                <el-table-column prop="create_time" label="创建时间" show-overflow-tooltip></el-table-column>
                <el-table-column label="操作" width="280" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <el-button @click.native.prevent="onClickEdit(scope.row)" type="primary" icon="el-icon-edit"
                            size="small">修改</el-button>
                        <el-button @click.native.prevent="onClickDel(scope.row)" type="danger" icon="el-icon-delete"
                            size="small">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-col>
        <!-- ========弹框BEGIN====== -->
        <el-dialog :title="formTitle" :visible.sync="dialogForm" width="720px" :close-on-click-modal="false" center
            class="formparams">
            <el-form :model="form" :label-width="formLabelWidth">
                <el-form-item label="任务名称">
                    <el-input v-model="form.title" placeholder="名称"></el-input>
                </el-form-item>                
                <el-form-item label="区服列表" :label-width="formLabelWidth">
                    <el-select v-model="form.group" placeholder="选择区服列表" value-key="id" @change="onFormSelectGroup" style="width: 100%;">
                        <el-option v-for="item in serverGroups" :key="item.id" :label="item.name" :value="item">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="区服" :label-width="formLabelWidth">
                    <el-select
                    v-model="form.serverids"
                    :multiple="true"
                    placeholder="不指定则是列表里全部区服" style="width: 100%;">
                        <el-option v-for="item in servers" :key="item.id" :label="item.title" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="执行时间">
                    <el-date-picker v-model="form.exec_time" type="datetime" placeholder="选择日期时间" style="width: 100%;">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="切到状态" :label-width="formLabelWidth">
                    <el-select v-model="form.operate_state" placeholder="区服状态" style="width: 100%;">
                        <el-option v-for="item in serverStates" :key="item.value" :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>  
                <el-form-item label="开关区服">
                    <el-select v-model="form.action_server" placeholder="区服操作" style="width: 100%;">
                        <el-option v-for="item in actionServer" :key="item.value" :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="上报开服事件">
                    <el-select v-model="form.action_report" placeholder="开服事件埋点" style="width: 100%;">
                        <el-option v-for="item in actionReport" :key="item.value" :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="开服时间设定">
                    <el-date-picker v-model="form.open_time" type="datetime" placeholder="选择日期时间" style="width: 100%;">
                    </el-date-picker>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" icon="el-icon-check" @click="onAddServerTask">确 定</el-button>
                <el-button icon="el-icon-close" @click="dialogForm = false">取 消</el-button>
            </div>
        </el-dialog>
        <!-- ========弹框END====== -->
        <!-- ========按量开服弹框BEGIN====== -->
        <el-dialog title="按量开服参数设定" :visible.sync="dialogFormByNum" width="720px" :close-on-click-modal="false" center
            class="formparams"  :key="updateFormNum">
            <el-form :model="formNum" label-width="130px">
                <el-form-item label="激活自动开服">
                    <el-switch v-model="formNum.active"  active-color="#13ce66" inactive-color="#9999a1"></el-switch>
                </el-form-item>                
                <el-form-item label="每创角数量设定">
                    <el-input v-model="formNum.rolenum" placeholder="每创角到数量则自动开户一个区服"></el-input>
                </el-form-item>
                <el-form-item label="正在累计创角区服">
                    <el-input v-model="formNum.curserver" :disabled="true"></el-input>
                </el-form-item>                
                <el-form-item label="待自动开服列表">
                  <el-table :data="formNum.servers" border style="width: 100%" height="300px" class="serverlist" size="mini">
                    <el-table-column label="ID"         prop="id" show-overflow-tooltip></el-table-column>
                    <el-table-column label="servername" prop="name" show-overflow-tooltip></el-table-column>
                    <el-table-column label="显示名称"    prop="title" show-overflow-tooltip></el-table-column>
                    <el-table-column label="状态" prop="state" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <el-tag :type="stateTagFormatColor(scope.row.state)"
                            :effect="stateTagFormatEffect(scope.row.state)">{{stateTagFormatTxt(scope.row.state)}}
                        </el-tag>
                    </template>
                    </el-table-column>
                  </el-table>
                </el-form-item>                
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" icon="el-icon-check" @click="onSetAutoOpenServer">确 定</el-button>
                <el-button icon="el-icon-close" @click="dialogFormByNum = false">取 消</el-button>
            </div>
        </el-dialog>
        <!-- ========弹框END====== -->
        <!-- ========按量开服弹框BEGIN====== -->
        <el-dialog title="自动推荐服监控" :visible.sync="dialogFormAutoRecommend" width="920px" :close-on-click-modal="false" center
            class="formparams"  :key="updateFormRecommend">
            <el-form :model="formRecommend" label-width="130px">
                <el-form-item label="区服列表">
                    <el-select v-model="formRecommend.group" placeholder="选择区服列表查看" value-key="id" style="width: 100%;"  @change="onFormRecommendSelectGroup" >
                        <el-option v-for="item in serverGroupsRaw" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                
                <el-form-item label="负载配置">
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="单区推荐人数">
                                <el-input v-model="formRecommend.rolenum" :disabled="true" size="mini"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="同时推荐区服数">
                                <el-input v-model="formRecommend.poolsize" :disabled="true" size="mini"></el-input>
                            </el-form-item> 
                        </el-col>
                    </el-row>
                </el-form-item>
                               
                <el-form-item label="正在推荐的区服">
                  <el-table :data="formRecommend.turning_servers" border style="width: 100%" height="280px" class="serverlist" size="mini">
                    <el-table-column label="ID"         prop="id" show-overflow-tooltip></el-table-column>
                    <el-table-column label="servername" prop="name" show-overflow-tooltip></el-table-column>
                    <el-table-column label="显示名称"    prop="title" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="operator" label="状态" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <el-tag :type="stateTagFormatColor(scope.row.state)"
                                :effect="stateTagFormatEffect(scope.row.state)">{{stateTagFormatTxt(scope.row.state)}}
                            </el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column label="已推荐次数"  prop="times" show-overflow-tooltip></el-table-column>
                    <el-table-column label="实际创角数"  prop="rolenum" show-overflow-tooltip></el-table-column>                    
                  </el-table>
                </el-form-item>
                
                <el-form-item label="等待推荐的区服">
                  <el-table :data="formRecommend.ready_servers" border style="width: 100%" height="200px" class="serverlist" size="mini">
                    <el-table-column label="ID"         prop="id" show-overflow-tooltip></el-table-column>
                    <el-table-column label="servername" prop="name" show-overflow-tooltip></el-table-column>
                    <el-table-column label="显示名称"    prop="title" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="operator" label="状态" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <el-tag :type="stateTagFormatColor(scope.row.state)"
                                :effect="stateTagFormatEffect(scope.row.state)">{{stateTagFormatTxt(scope.row.state)}}
                            </el-tag>
                        </template>
                    </el-table-column>                    
                    <el-table-column label="实际创角数"  prop="rolenum" show-overflow-tooltip></el-table-column>                     
                  </el-table>
                </el-form-item>                
                
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="success" icon="el-icon-check" @click="onRefreshRecommend">刷新</el-button>
                <el-button icon="el-icon-close" @click="dialogFormAutoRecommend = false">关闭</el-button>
            </div>
        </el-dialog>
        <!-- ========弹框END====== -->
    </el-row>
</template>

<script>
    import {
        mapGetters
    } from 'vuex';
    import tool from '../../tool.js';
    import config from '../../config.js';

    export default {
        data() {
            return {
                mergeList: [],
                formTitle: '',
                titleSelectServer: '',
                dialogForm: false,
                form: {
                    id: '',
                    title: '',
                    group: '',
                    serverids: [],
                    exec_time: '',
                    open_time: '',
                    operate_state:'',
                    action_server:'',
                    action_report:'',
                },
                search: '',
                servers: [], // 可选的区服
                serverGroups: [], 
                formLabelWidth: '100px',
                tableHeight: document.documentElement.clientHeight-136,
                serverStates: config.serverStates,
                actionServer: [{
                        value: "start",
                        label: "开服",
                    },
                    {
                        value: "stop",
                        label: "关服",
                    },
                    {
                        value: "kick_all",
                        label: "踢下线",
                    },                    
                    {
                        value: "none",
                        label: "无", 
                    },
                ],
                actionReport: [{
                        value: "yes",
                        label: "上报埋点",
                    },
                    {
                        value: "no",
                        label: "不用上报", 
                    },
                ],                
                // 按量开服参数
                dialogFormByNum: false,
                updateFormNum: 0,
                formNum : {
                    rolenum: "", // 创角数量
                    active: false, // 激活状态
                    curserver: "", // 当前累计的区服
                    servers:[{id:"1",name:"test",title:"title",state:1}],
                },
                // 自动推荐服监控
                serverGroupsRaw:[],
                dialogFormAutoRecommend: false,
                updateFormRecommend: 1000,                
                formRecommend: {
                    group:1,
                    rolenum:0, // 单区推荐人数
                    poolsize:0,// 同时推荐区服
                    turning_servers:[], // 正在推荐
                    ready_servers:[], // 等待推荐
                },
            }
        },
        created: function() {
            this.refreshTaskList()
            this.refreshGroupList()
        },
        computed: {
            ...mapGetters(
                ['gameHttp']
            ),
        },
        methods: {
            // 刷新列表
            refreshTaskList() {
                this.gameHttp(1051, {}).then((response) => {
                    this.mergeList = response.data.list
                })
            },           
            // 刷新分组列表
            refreshGroupList() {
                this.gameHttp(2006, {
                    plain: 1
                }).then((response) => {
                    this.serverGroupsRaw = JSON.parse( JSON.stringify(response.data.list) );
                    this.serverGroups = response.data.list
                    this.serverGroups.splice(0, 0, {
                        id: -1,
                        name: "全部区服列表"
                    })
                })
            },
            // 弹出新增编辑框
            onClickAdd() {
                this.formTitle = '新增任务'
                this.dialogForm = true
                this.servers = []
                for (let k in this.form) {
                    this.form[k] = ''
                }
                this.form.serverids = [];
            },
            // 弹出修改编辑框
            onClickEdit(row) {
                this.formTitle = '修改任务'
                this.dialogForm = true
                this.servers = row.servers;
                // 参数拷贝显示
                this.form = JSON.parse(JSON.stringify(row));
                for (let i=0; i<this.serverGroups.length; i++) {
                    if (this.form.group == this.serverGroups[i].id) {
                        this.form.group = this.serverGroups[i];
                        break;
                    }
                }
                this.form.exec_time = new Date(this.form.exec_time);
                if (this.form.open_time) {
                    this.form.open_time = new Date( this.form.open_time );
                }
                delete this.form.update_time // 忽略不需要提交的字段
                delete this.form.create_time // 忽略不需要提交的字段
            },
            // 删除
            onClickDel(row) {
                this.$confirm('是否删除[ '+row.title+' ]任务?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.gameHttp(1053, {
                        id: row.id
                    }).then((response) => {
                        if (0 == response.data.errcode) {
                            this.$message.success("删除成功");
                            this.refreshTaskList();
                        }
                    })
                }).catch(() => {})
            },
            // 弹出按量开服参数设定
            onClickSetNum() {
                this.dialogFormByNum = true;
                this.gameHttp(1055, { // 拉取当前信息
                    op: 0,
                }).then((response) => {
                    this.formNum.rolenum = response.data.rolenum;
                    this.formNum.active = 0==response.data.active?false:true;
                    this.formNum.servers = response.data.servers;
                    this.formNum.curserver = response.data.curserver;
                })
            },
            // 弹出自动推荐面板
            onClickAutoRecommend() {
                this.dialogFormAutoRecommend = true;
                this.gameHttp(1056, { // 拉取当前信息
                    op: 0,
                    groupid: this.formRecommend.group,
                }).then((response) => {
                    this.formRecommend.rolenum = response.data.pool_rolenum;
                    this.formRecommend.poolsize = response.data.pool_size;
                    this.formRecommend.turning_servers = response.data.turning_servers;
                    this.formRecommend.ready_servers = response.data.ready_servers;
                })
            },            
            // 设置按量自动开服
            onSetAutoOpenServer() {
                let rolenum = parseInt(this.formNum.rolenum);
                if (!rolenum || rolenum<= 0) {
                    this.$message.error('请输入正确的角色数量值！'); return;
                }                
                let rqs = {
                    op: 1,
                    rolenum: this.formNum.rolenum,
                    active: this.formNum.active ? 1 : 0,
                };
                this.gameHttp(1055, rqs).then(() => {
                    this.$message.success('提交成功！');
                });
                this.dialogFormByNum = false;
            },
            // 强制刷新自动推荐
            onRefreshRecommend() {
                this.dialogFormAutoRecommend = true;
                this.gameHttp(1056, { // 拉取当前信息
                    op: 1,
                    groupid: this.formRecommend.group,
                }).then((response) => {
                    this.formRecommend.rolenum = response.data.pool_rolenum;
                    this.formRecommend.poolsize = response.data.pool_size;
                    this.formRecommend.turning_servers = response.data.turning_servers;
                    this.formRecommend.ready_servers = response.data.ready_servers;
                })
            },            
            // 表单选择分组
            onFormSelectGroup(item) {
                this.gameHttp(2009, { // 拉取服务器列表
                    listid: item.id,
                }).then((response) => {
                    this.servers = response.data.list
                })
            },
            // 表单选择分组
            onFormRecommendSelectGroup(groupid) {
                this.dialogFormAutoRecommend = true;
                this.gameHttp(1056, { // 拉取当前信息
                    op: 0,
                    groupid: groupid,
                }).then((response) => {
                    this.formRecommend.rolenum = response.data.pool_rolenum;
                    this.formRecommend.poolsize = response.data.pool_size;
                    this.formRecommend.turning_servers = response.data.turning_servers;
                    this.formRecommend.ready_servers = response.data.ready_servers;
                })
            },            
            // 添加任务
            onAddServerTask() {
                if (0 == this.form.title.length) {
                    this.$message.error('请指定名称！'); return;
                }
                if (!this.form.group) {
                    this.$message.error('请选择区服列表'); return;
                }
                if (!this.form.exec_time) {
                    this.$message.error('请选择执行时间'); return;
                }
                if (!this.form.operate_state) {
                    this.$message.error('请指定要切到的状态'); return;
                }                
                if (!this.form.action_server) {
                    this.$message.error('请指定开关区服操作'); return;
                }
                if (!this.form.action_report) {
                    this.$message.error('请指定是否需要上报埋点操作'); return;
                }                
                this.dialogForm = false
                // 请求操作                      
                let rqs = JSON.parse(JSON.stringify(this.form));
                rqs.exec_time = tool.dateFormat(this.form.exec_time);
                rqs.groupid = this.form.group.id;
                rqs.opents = this.form.open_time ?  Date.parse(this.form.open_time)/1000 : 0;
                this.gameHttp(1052, rqs).then((response) => {
                    if (0 == response.data.errcode) {
                        this.refreshTaskList()
                        this.$message.success("提交成功")
                    } else {
                        this.$message.error(response.data.errmsg)
                    }
                })
            },
            // 状态列的标签格式化显示
            stateTagFormatEffect(statev) {
                return (2 == statev) ? "light" : "dark"
            },
            stateTagFormatTxt(statev) {
                return this.serverStates[statev - 1].label
            },
            stateTagFormatColor(statev) {
                return this.serverStates[statev - 1].tag
            },
            // 数据表色彩间隔效果     
            tableRowClassName({
                rowIndex
            }) {
                if (0 == rowIndex % 2) {
                    return 'warning-row';
                } else {
                    return 'success-row';
                }
            },
        },
    }
</script>

<style>
    .serverlist.el-table .warning-row {
        background: #f5f7fa;
    }

    .serverlist.el-table .success-row {
        background: #ffffff;
    }
    
    .serverlist.el-table .error-row {
        color: #ff0000;
        font-weight: bold;
    }   
    .serverlist.el-table .warnlog-row {
        color: #ffaa00;
        font-weight: bold;
    }       

    .serverlist.el-table {
        margin-top: 16px;
    }

    .serverlist {
        font-size: 12px;
    }

    .desc-item-content-stype {
        background: #F56C6C;
        font-weight: bold;
    }
    .desc-item-content-sno {
        background: #409EFF;
        font-weight: bold;
    }
    .desc-item-content-scur {
        background: #67C23A;
    }    
    .desc-item-content-snext {
        background: #C0C4CC;
    }    
</style>
